<template>
  <article-wrapper heading="Design Fees">
    <p>
      Conceptual Master Plans are a great start for projects of any size. Our
      consultation will address Plantings, Hardscaping, Grading, Drainage,
      Lighting, Irrigation and Cost Considerations whether you intend to do the
      work yourself or hire a contractor. Our brainstorming session will
      address your objectives and concerns while capitalizing on your
      opportunities.
    </p>
    <img
      loading="lazy"
      style="padding-bottom: 24px;"
      alt="Dan Chitwood mapping out a design and surveying the
          landscape in front of a small pink flowering tree."
      :src="require('@/assets/photos/original/dan-survey-pad-flowers.jpg')"
    >
  </article-wrapper>
</template>
